import React from "react";

function Loading_CV(){

    return(
        <div className="loadingCVBox">
            <span className="small"></span>
            <span className="big"></span>
            <span className="small"></span>
        </div>
    )
}

export default Loading_CV;